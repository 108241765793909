import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Select, Space, Tree } from 'antd';
import { DataNode, TreeProps } from 'antd/lib/tree';
import Text from 'antd/lib/typography/Text';
import { sortBy } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../../components/Layout';
import { Spinner } from '../../components/Spinner';
import { useAppContext } from '../../modules/app';
import config from '../../modules/dynamic-config';
import { useKeyData } from '../../modules/hasura/useKeyData';
import { MenuItem } from '../../utils/common';

function MenuNode({
  item,
  onRemove,
}: {
  item: MenuItem;
  onRemove?: () => void;
}) {
  return (
    <Space className="menu-node">
      <Text>{item.title}</Text>
      <Text type="secondary">{item.path}</Text>
      <Button
        type="link"
        icon={<DeleteOutlined />}
        onClick={onRemove}
        style={{ padding: 0, height: 'auto' }}
      />
    </Space>
  );
}

const roles = config.menuRoles;

function MenuSetup() {
  const { resources } = useAppContext();
  const flag = useRef({ loaded: false, changed: false });
  const { data, loading, save } = useKeyData('dataSettings.menus');
  const [menus, setMenus] = useState<Record<string, MenuItem[]>>({});

  const initialing = loading && !flag.current.loaded;

  // init data
  useEffect(() => {
    if (!loading && flag.current.loaded === false) {
      flag.current.loaded = true;
      setMenus(data);
    }
  }, [loading]);

  // auto save
  useEffect(() => {
    if (!flag.current.changed) {
      return;
    }
    save({
      set: menus,
    });
  }, [menus]);

  // options to add to menus
  const menuOptions = Object.entries(resources).map<MenuItem>(([key, item]) => {
    const menuPath = `$list::${key}`;
    return {
      path: menuPath,
      title: item.dataSetting?.title || key,
    };
  });

  // add menu to role
  const addMenu = (role: string, path: string) => {
    const item = menuOptions.find((item) => item.path === path);
    if (!item) {
      return;
    }
    flag.current.changed = true;
    setMenus((prev) => {
      const menuRoles = prev[role] || [];
      return {
        ...prev,
        [role]: [
          ...menuRoles,
          { ...item, key: `menu::${new Date().getTime()}` },
        ],
      };
    });
  };

  // drag-drop menu
  const drop = (
    role: string,
    info: Parameters<Required<TreeProps>['onDrop']>[0]
  ) => {
    const { dragNodesKeys, dropPosition } = info;
    const [dragKey] = dragNodesKeys;
    const menusRoles = menus[role];

    // dropPosition range: -1 -> length
    const nextRoleMenus = sortBy(menusRoles, (item) => {
      if (item.key === dragKey) {
        return dropPosition - 0.5; // make insert before if equal position
      }
      return menusRoles.findIndex((mItem) => mItem.key === item.key);
    });
    flag.current.changed = true;
    setMenus((prev) => ({
      ...prev,
      [role]: nextRoleMenus,
    }));
  };

  // remove menu
  const remove = (role: string, key: string) => {
    flag.current.changed = true;
    setMenus((prev) => ({
      ...prev,
      [role]: (prev[role] || []).filter((item) => item.key !== key),
    }));
  };

  // menus to tree data
  const menuTree = useMemo<Record<string, DataNode[]>>(() => {
    const entries = Object.entries(menus).map(([key, items]) => {
      return [
        key,
        items.map((item) => {
          return {
            title: (
              <MenuNode
                item={item}
                onRemove={() => remove(key, item.key as string)}
              />
            ),
            key: item.key,
          };
        }),
      ];
    });
    return Object.fromEntries(entries);
  }, [menus]);

  return (
    <Layout>
      <Helmet>
        <title>Setup Menus</title>
        <style type="text/css">{`
          .menu-node .ant-btn {
            visibility: hidden;
            transition: none;
          }
          .menu-node:hover .ant-btn {
            visibility: visible;
          }
        `}</style>
      </Helmet>
      <Card
        title="Sidebar Menus"
        loading={initialing}
        extra={loading && !initialing ? <Spinner /> : undefined}
      >
        <Row gutter={16}>
          {roles.map((role) => {
            return (
              <Col key={role} span={12}>
                <Card type="inner" title={role}>
                  <Space direction="vertical">
                    <Select
                      showSearch
                      style={{ minWidth: 200 }}
                      placeholder="Add menu"
                      onChange={(v: string) => addMenu(role, v)}
                      value={null}
                    >
                      {menuOptions.map((item) => (
                        <Select.Option key={item.path} value={item.path}>
                          {item.title}
                        </Select.Option>
                      ))}
                    </Select>
                    <Tree
                      showIcon
                      selectable={false}
                      draggable
                      treeData={menuTree[role]}
                      onDrop={(info) => drop(role, info)}
                    />
                  </Space>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Card>
    </Layout>
  );
}

export default MenuSetup;
